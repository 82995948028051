import {
  GETDETAILS,
  GETVISITLIST,
  GETFORMLIST,
  SAVECODE,
  SAVEIMG,
  SAVEADDDATA
} from "./mutations-type";

function formatterDefaultValue(e) {
  if (e.value) {
    try {
      if (Array.isArray(JSON.parse(e.value))) {
        return JSON.parse(e.value)[0];
      } else {
        return e.controlType === "CB" || e.controlType === "ADSEL"
          ? e.value.split(",")
          : e.value;
      }
    } catch (error) {
      console.log(e.value);
      if (Array.isArray(e.value)) {
        return e.value;
      } else {
        return e.controlType === "CB" || e.controlType === "ADSEL"
          ? e.value.split(",")
          : e.value;
      }
    }
  } else {
    return e.controlType === "CB" || e.controlType === "ADSEL" ? [] : "";
  }
}
export default {
  [GETDETAILS](state, value) {
    let val = value;
    val.ddMap.map(e => {
      if (e.isRepeat == 1) {
        Object.values(e.groupMap).map((item, index) => {
          item.map(v => {
            v.hidden = item.visible == 0;
            v.value = formatterDefaultValue(v);
            // if (v.controlType === 'CB') {
            //     (v.value && v.value.length > 0) ? v.value = v.value.split(','): v.value = []
            // }
            // if (v.controlType === 'TP') {
            //     if (v.value) {
            //         let time = '2020-11-10 ' + v.value
            //         v.value = new Date(time)
            //     }
            // }
            // if (v.controlType === 'SUF') {
            //     v.value ? v.value = JSON.parse(v.value) : v.value = []
            // }
            // if (v.controlType === 'ADSEL') {
            //     (v.value && v.value.length > 0) ? v.value = v.value.split(','): v.value = []
            // }
          });
        });
      }
      if (e.isRepeat == 0) {
        Object.values(e.groupMap).map(item => {
          item.hidden = item.visible == 0;
          item.value = formatterDefaultValue(item);
        });
      }
    });
    state.detailsData = val;
  },
  [GETVISITLIST](state, value) {
    state.visitList = value;
  },
  [GETFORMLIST](state, value) {
    state.formList = value;
  },
  [SAVECODE](state, value) {
    state.formDetails = value;
  },
  [SAVEIMG](state, value) {
    let imgData = [];
    if (value) {
      imgData = value.map(item => {
        return item.fileUrl;
      });
    }
    state.imgData = imgData;
  },
  [SAVEADDDATA](state, value) {
    let list = [...Object.values(value)];
    list.map(item => {
      if (item.controlType === "CB") {
        item.value = item.value.split(",");
      }
      if (item.controlType === "TP") {
        if (item.value) {
          let time = "2020-11-10 " + item.value;
          item.value = new Date(time);
        }
      }
      if (item.controlType === "SUF") {
        item.value ? (item.value = JSON.parse(item.value)) : (item.value = []);
      }
    });
    state.ddMap = list;
  }
};
