import ajax from "./axios";
import QS from "qs";
export const getForm = data =>
  ajax({
    url: "/weixin/formDetail",
    method: "POST",
    data
  }); //研究列表
export const getReSearchList = () =>
  ajax({
    url: "/weixin/myResearch",
    method: "POST",
    headers: {
      isNeedToken: true
    }
  });
//绑定研究
export const bindingReSearch = code =>
  ajax({
    url: `/weixin/bindResearch?code=${code}`,
    method: "POST",
    headers: {
      isNeedToken: true
    }
  });
export const getVisitList = (researchcode, versionCode) =>
  ajax({
    url: "/weixin/visitList",
    method: "POST",
    params: {
      researchcode,
      versionCode
    },
    headers: {
      isNeedToken: true
    }
  });
export const getFormList = (code, versionCode, registryEntityCode, exampleId) =>
  ajax({
    url: "/weixin/formList",
    method: "POST",
    params: {
      code,
      versionCode,
      registryEntityCode,
      exampleId
    },
    headers: {
      isNeedToken: true
    }
  });
export const getTime = time =>
  ajax({
    url: "/weixin/mouthVisit",
    method: "POST",
    params: {
      time
    },
    headers: {
      isNeedToken: true
    }
  });
export const getTimeDetails = time =>
  ajax({
    url: "/weixin/mySchedule",
    method: "POST",
    params: {
      time
    },
    headers: {
      isNeedToken: true
    }
  });
export const saveFrom = data =>
  ajax({
    url: "/ddValue/saveBeanGroups",
    method: "POST",
    data
  });
export const upload = (data, code, id) => {
  return ajax({
    url: "/weixin/uploadFile",
    method: "POST",
    data,
    headers: {
      code,
      id
    }
  });
};
export const addForm = data =>
  ajax({
    url: "/ddValue/getStructureDdListGroup",
    method: "POST",
    data
  });
export const deleteGroup = data =>
  ajax({
    url: "/ddValue/deleteBeanByExampleId",
    method: "POST",
    data
  });
export const RecruitForm = researchCode =>
  ajax({
    url: "/weixin/recruitFromValue",
    method: "POST",
    params: {
      researchCode
    },
    headers: {
      isNeedToken: true
    }
  });
//选择中心
export const getCenter = researchCode =>
  ajax({
    url: "/weixin/centerList",
    method: "POST",
    params: {
      researchCode
    }
  });
//表单提交
export const RecruitAdd = data =>
  ajax({
    url: "/weixin/saveFromValue",
    method: "POST",
    data,
    headers: {
      isNeedToken: true
    }
  });
