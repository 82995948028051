import axios from "axios";
// import Vue from "vue";
import "muse-ui-loading/dist/muse-ui-loading.css";
// http://production.dhcmetis.com/api/
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
});
instance.interceptors.request.use(config => {
  if (config.headers.isNeedToken) {
    let token = sessionStorage.getItem("token");
    config.headers.toKen = token;
  }
  return config;
});
instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);
export default instance;
