import "lib-flexible";
import Vue from "vue";
import App from "./App.vue";
import "muse-ui/lib/styles/base.less";
import Helpers from "muse-ui/lib/Helpers";
import "muse-ui-loading/dist/muse-ui-loading.css";
import "muse-ui/lib/styles/theme.less";
import Loading from "muse-ui-loading";
import Toast from "muse-ui-toast";
import {
  Button,
  Form,
  Select,
  TextField,
  Dialog,
  List,
  Icon,
  Checkbox,
  DateInput,
  Radio,
  Grid,
  SlidePicker,
  Paper,
  Picker,
  BottomSheet,
  Snackbar,
  Progress,
  ExpansionPanel,
  Tooltip
} from "muse-ui";
import { Image, Card } from "element-ui";
import router from "./router";
import store from "./store";
import "@/assets/common.scss";
import wx from "weixin-jsapi";
import "../public/iconfont/iconfont.js";
import * as API from "./api//index";
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
Vue.prototype.$wx = wx;
Vue.prototype.$API = API;
Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Select);
Vue.use(Form);
Vue.use(TextField);
Vue.use(Dialog);
Vue.use(List);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Helpers);
Vue.use(DateInput);
Vue.use(Radio);
Vue.use(Grid);
Vue.use(SlidePicker);
Vue.use(Paper);
Vue.use(Picker);
Vue.use(Snackbar);
Vue.use(Image);
Vue.use(Card);
Vue.use(Loading);
Vue.use(Progress);
Vue.use(Tooltip);
Vue.use(ExpansionPanel);
Vue.use(preview, options);
Vue.use(Toast, {
  position: "top", // 弹出的位置
  time: 1000,
  successIcon: "",
  errorIcon: "", // 显示的时长
  closeIcon: "",
  actions: []
});
let options = {
  fullscreenEl: false, //控制是否显示右上角全屏按钮
  closeEl: false, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //控制是否显示分享按钮
  zoomEl: false, //控制是否显示放大缩小按钮
  counterEl: false, //控制是否显示左上角图片数量按钮
  tapToToggleControls: true, //点击应切换控件的可见性
  clickToCloseNonZoomable: true, //点击图片应关闭图库，仅当图像小于视口的大小时
  indexIndicatorSep: " / " //图片数量的分隔符
};

function getToken() {
  let token = this.$route.query.token;
  if (token) sessionStorage.setItem("token", token);
}
Vue.prototype.$Loading = Loading;
Vue.prototype.$Token = getToken;
Vue.use(BottomSheet);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
