import { getForm, getVisitList, getFormList } from "@/api/index.js";
import {
  GETDETAILS,
  GETVISITLIST,
  GETFORMLIST,
  SAVECODE,
  SAVEIMG
} from "./mutations-type";
export default {
  async getDetailsActions({ commit }, item) {
    let mapList = localStorage.getItem("mapList")
      ? JSON.parse(localStorage.getItem("mapList"))
      : [];
    const data = {
      code: item.code,
      parentCode: item.parentCode,
      patientId: item.patientId,
      registryEntityCode: item.registryEntityCode,
      roleId: item.roleId,
      selectionType: item.selectionType,
      versionCode: item.versionCode,
      exampleId: item.exampleId,
      parentExampleId: item.parentExampleId
    };
    getForm(data)
      .then(res => {
        if (res.data.fileUrl && res.data.fileUrl.length > 0) {
          commit(SAVEIMG, res.data.fileUrl);
        }
        if (res.code === 200) {
          if (mapList.length > 0) {
            mapList.map((v, k) => {
              if (
                v.isRepeat === "1" &&
                v.groupCode === res.data.ddMap[k].groupCode
              ) {
                v.groupMap = res.data.ddMap[k].groupMap;
              }
            });
            commit(GETDETAILS, {
              ddMap: mapList
            });
            // localStorage.setItem('',JSON.stringify(mapList))
            commit(SAVECODE, item);
          } else {
            commit(GETDETAILS, res.data);
            commit(SAVECODE, item);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  async getVisitListActions({ commit }, data) {
    try {
      let result = await getVisitList(data.researchcode, data.versionCode);
      if (result.code === 200) {
        console.log(result.data);
        commit(GETVISITLIST, result.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getFormListActions({ commit }, data) {
    console.log(data);
    try {
      let result = await getFormList(
        data.code,
        data.versionCode,
        data.registryEntityCode,
        data.exampleId
      );
      if (result.code === 200) {
        commit(GETFORMLIST, result.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
};
