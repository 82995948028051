<template>
  <div id="app">
    <mu-slide-left-transition>
      <router-view />
    </mu-slide-left-transition>
  </div>
</template>
<script>
import "./assets/style.scss";
export default {};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // position: absolute;
  // left: 0;
  // top: 0;
  // bottom: 0;
  // right: 0;
}
</style>
