import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "myReasarch",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MyResearch/selectRole.vue"
      )
  },
  {
    path: "/doctor",
    name: "Doctor",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Doctor/not.vue")
    // children:[
    //   {
    //     path:'/not',
    //     name:'Not',
    //     component: () =>
    //       import(/* webpackChunkName: "about" */ "../views/Doctor/not.vue"),
    //   }
    // ]
  },
  {
    path: "/patient",
    name: "Patient",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Patient/patientHome.vue")
  },
  {
    path: "/binding",
    name: "Binding",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Patient/bindingResearch.vue"
      )
  },
  {
    path: "/visit",
    name: "Visit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Patient/visitList.vue")
  },
  {
    path: "/form",
    name: "Form",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Patient/formList.vue")
  },
  {
    path: "/details",
    name: "Details",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Patient/formDetails.vue")
  },
  {
    path: "/date",
    name: "Date",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Patient/myDate.vue")
  },
  {
    path: "/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactUs/support.vue")
  },
  {
    path: "/case",
    name: "Case",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Patient/case.vue")
  },
  {
    path: "/addForm",
    name: "AddForm",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Patient/addForm.vue")
  },
  {
    path: "/recruit",
    name: "Recruit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Recruit/recruit.vue")
  },
  {
    path: "/no",
    name: "No",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/noReSearch.vue")
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
